import React, {useEffect, useState} from "react";
import {ApiClient} from "../services/api";
import {Auction} from "../global";
import {Table} from "react-bootstrap";
import {Loading} from "../components/Loading";

export const AuctionHousePage = () => {

    const [auctions, setAuctions] = useState<Auction[]>()

    useEffect(() => {
        const fetch = async () => {
            const client = new ApiClient();

            const auctions = await client.get('auction');

            setAuctions(auctions )
        }
        
        fetch()
    }, []);
    
    
    if(!auctions) return <Loading/>
    
    return <>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Type</th>
                <th>Item</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(auctions).map(([key, auction]) => (
                <tr key={key}>
                    <td style={{width: '10%'}} className=''>{auction.item.type}</td>
                    <td style={{width: '10%'}} className=''>{auction.item.name}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    </>
}