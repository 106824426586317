import React, {useEffect, useState} from "react";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {ItemTable} from "../components/ItemTable";
import {PageContent} from "../ux/Card";
import {Item} from "../global";
import {toast} from "react-toastify";

export type Actions = {
    allowedItemTypes: [string]
    name: string
    func: any
}

export type InventoryProps = {
    actions: Actions[]
}

export const Inventory = (props: InventoryProps) => {
    
    const [inventory, setInventory] = useState<any>()
    
    useEffect(() => {
        const fetch = async () => {
            const api = new ApiClient()

            const data = await api.get('inventory');
            
            setInventory(data);
        }

        fetch()

    }, []);
    
    if(!inventory) return <Loading/>

    return <>
        <PageContent title="Inventory" children={
            <ItemTable items={inventory.items} actions={[]}/>
        } ></PageContent>
    </>
}