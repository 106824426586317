import {Table} from "react-bootstrap";
import {Button} from "../layout/Button";
import React, {useState} from "react";
import {Item} from "../global";
import {ApiClient} from "../services/api";
import {toast} from "react-toastify";

export type Actions = {
    allowedItemTypes: string[]
    name: string
    func: any
}

export type ItemProps = {
    items: Item[]
    actions: Actions[]

}
export const ItemTable = (props: ItemProps) => {
    
    const [items, setItems] = useState<Item[]>(props.items)
    
    
    const sendForRepairs = async (item: Item) => {

        delete items[item.id];
        
        setItems({...items})
        
        toast.info(`${item.name} sent for Repairs`);
        
        const client = new ApiClient();
        
        await client.post("Blacksmith/repairs/start", {
            itemId: item.id
        })
    }

    const createAuction = async (item: Item) => {

        delete items[item.id];

        setItems({...items})
        
        toast.info(`${item.name} sent for auction`);

        const api = new ApiClient()

        await api.post('auction', {
            itemId: item.id
        });
    }
    
    return <>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Type</th>
                <th>Item</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(items).map(([key, item]) => (
                <tr key={key}>
                    <td style={{width: '10%'}} className=''>{item.type}</td>
                    <td style={{width: '10%'}} className=''>{item.name}</td>
                    <td>
                        
                        <Button onClick={() => sendForRepairs(item)} name="Repair"/>
                        <Button onClick={() => createAuction(item)} name="Auction"/>
                        
                        {props.actions.map((action: any) => (
                            action.allowedItemTypes.includes(item.type) && <Button onClick={() => action.func(item)} name={action.name}></Button>
                        ))}
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    </>
}